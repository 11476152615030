import $ from "jquery";

const noLeftScroll = () => {
  const body = $(document);
  body.on("scroll", function () {
    if (body.scrollLeft() !== 0) {
      body.scrollLeft(0);
    }
  });
};

export { noLeftScroll };
